import React from 'react'

const PlaceHolder: React.FC = () => {
  return (
    <div style={{height: '8vh', width: '100%'}}>

    </div>
  )
}

export default PlaceHolder